import { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import classes from "./questions.module.scss";
// import Input from 'react-phone-number-input/input'

const Questions = () => {
	const [isOpenOne, setIsOpenOne] = useState(false); 
	const [isOpenTwo, setIsOpenTwo] = useState(false); 
	const [isOpenThree, setIsOpenThree] = useState(false); 
	const [isOpenFour, setIsOpenFour] = useState(false); 
	const [isOpenFive, setIsOpenFive] = useState(false); 

	// const [focus, setFocus] = useState(false);
	// const [setName] = useState('');
	const [setError] = useState('');
	const [value] = useState("");
	function nameSetting(event) {
		//  setName(event);
		 event && setError(event.trim().length<12)
	 }
 useEffect(()=>{
    nameSetting(value)
  },[value])
    return ( 
        <section className={classes.question}>
			<section className={classes.container}>
				<div className={classes.titlewrap}>
					<h2>Часто задаваемые <span>вопросы</span></h2>
				</div>
				<div className={classes.subtitlewrap}>
					<p>Мы стараемся отвечать на все вопросы наших клиентов
					</p>
				</div>

				<div className={classes.halfwrap}>
					<div className={classes.halfitem + " " +classes.halfitem1}>
						{/* <div className={classes.questionform}> */}
							{/* <div className={classes.questionform__img} >
								<p>Не нашли ответа на свой вопрос?</p>
							</div>
							<div className={classes.questionform__title}>
								<p className={classes.questionform__title1}>Задайте его нам прямо сейчас</p>
								<p className={classes.questionform__title2}>Мы обязательно ответим в ближайшее время</p>
							</div> */}
							{/* <div className={classes.questionform__form}>
								<form action="telegram.php" method="post">
									<input type="text" name="name" className={classes.name} placeholder="Ваше имя..."></input>

									<Input
										name="phone"  
										className={classes.phone} 
										style={{border: error&focus ? 'red 1px solid' : focus?'green 1px solid': '1px solid #9FACDC'}}  
										required 
										onFocus={()=> {setFocus(true)}} 
										onBlur={()=> setFocus(false)}  
										country="RU"
										maxLength="16"
										minLength="16"
										international
										withCountryCallingCode
										value={value}
										onChange={setValue}
										/>

									<textarea className={classes.place} type="text" rows="10" cols="15" name="otzz" placeholder="Ваш вопрос..." style={{width:'100%', outline: 'none'}} ></textarea>
									<button  className= { error? classes.btn1 + " " + classes.disable :classes.btn1}  type="submit" >Отправить</button>
								</form>
							</div> */}
						{/* </div> */}
					</div>	 
					<div className={classes.halfitem + " " +  classes.halfitem2}>

						
						<div className={classes.accordion}>
							<div className={classes.accordion__item}>
								<button className={classes.accordion__head} onClick={() => setIsOpenTwo(!isOpenTwo)}>
									<p>Есть ли гарантия на работу?</p>
									<div className={classes.accordion__headimg}>
										<img className={classes.accordionicon1} src={require("./arrow_down.webp")} alt="arrow"></img>
									</div>
								</button>
								<CSSTransition 	in={isOpenTwo} timeout={800} classNames="card" unmountOnExit>
								<div className={classes.accordion__description}>Мы несем ответственность за качество оказанных услуг. Гарантия действует от 3 до 10 лет.</div>
								</CSSTransition>
							</div>

							<div className={classes.accordion__item}>
									<button className={classes.accordion__head} onClick={() => setIsOpenOne(!isOpenOne)}>
										<p>Как оплатить услуги электрика?</p>
										<div className={classes.accordion__headimg}>
											<img className={classes.accordionicon1} src={require("./arrow_down.webp")} alt="arrow" ></img>
										</div>
									</button>
								<CSSTransition 	in={isOpenOne} timeout={800} classNames='card' unmountOnExit>
									<div className={classes.accordion__description}>Принимаем оплату любым удобным способом: наличными, терминалом, по QR-коду, переводом на карту или расчётные счета.</div>
								</CSSTransition>
							</div>

							<div className={classes.accordion__item}>
								<button className={classes.accordion__head} onClick={() => setIsOpenFour(!isOpenFour)}>
									<p>Вы контролируете качество обслуживания?</p>
									<div className={classes.accordion__headimg}>
										<img className={classes.accordionicon1} src={require("./arrow_down.webp")} alt="arrow"></img>
									</div>
								</button>
								<CSSTransition 	in={isOpenFour} timeout={800} classNames="card" unmountOnExit>
								 	<div className={classes.accordion__description}>Мы контролируем работу специалистов на всех этапах - потому, что нет ничего дороже репутации.</div>
								 </CSSTransition>
							</div>

							<div className={classes.accordion__item}>
								<button className={classes.accordion__head} onClick={() => setIsOpenThree(!isOpenThree)}>
									<p>Как быстро мастер приезжает на объект?</p>
									<div className={classes.accordion__headimg}>
										<img className={classes.accordionicon1} src={require("./arrow_down.webp")} alt="arrow" ></img>
									</div>
								</button>
								<CSSTransition 	in={isOpenThree} timeout={800} classNames="card" unmountOnExit>
									 <div className={classes.accordion__description}>В среднем через один  час после оформления заявки и подтверждения заказа.</div>
								</CSSTransition>
							</div>

							<div className={classes.accordion__item}>
								<button className={classes.accordion__head} onClick={() => setIsOpenFive(!isOpenFive)}>
									<p>Есть ли у Вас скидки для постоянных клиентов?</p>
									<div className={classes.accordion__headimg}>
										<img className={classes.accordionicon1} src={require("./arrow_down.webp")} alt="arrow"></img>
									</div>
								</button>
								<CSSTransition 	in={isOpenFive} timeout={800} classNames="card" unmountOnExit>
									 <div className={classes.accordion__description}>Многие наши Заказчики подтверждают, что наши цены одни из лучших по региону. Звоните и узнайте о специальных ценах и скидках.</div>
								 </CSSTransition>
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
     );
}
 
export default Questions;