import classes from "./service.module.scss"

const Service = ({openModal, phoneNumber}) => {
    
    return ( 
   
        <section className={classes.services}>
        <section>
            <div className={classes.titlewrap}>
                <h2>Наши <span className={classes.span}>услуги</span></h2>
            </div>
            <div className={classes.subtitlewrap}>
                <p>Предоставляем 900+ видов услуг
                </p>
            </div>
            <div className={classes.serviceswrap}>  

                <div className={classes.servicesitem}>
                    <div className={classes.servicesitem__img} style={{backgroundImage: `url(${require('./imgs/newhands.webp')})` }} ></div>
                    <div className={classes.servicesitem__content}>
                    {(window.innerWidth <= 1250 & window.innerWidth> 505)  ? (<div className={classes.servicesitem__title}>Ремонт и <br /> замена розеток</div>):
                        (<div className={classes.servicesitem__title}>Ремонт и замена розеток</div>)}
                        <div className={classes.servicesitem__price}>
                            <div className={classes.servicesitem__price_name}>Стоимость работ</div>
                            <div className={classes.servicesitem__price_price}>от 290 руб.</div>
                        </div>
                    </div>
                    <div className={classes.servicesitem__btn}>
                        {/* <button onClick={openModal}  className={classes.btn}>Оставить заявку</button> */}
                        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
                    </div>
                </div>
                <div className={classes.servicesitem}>
                    <div className={classes.servicesitem__img} style={{backgroundImage: `url(${require('./imgs/hand.webp')})` }}></div>
                    <div className={classes.servicesitem__content}>
                        <div className={classes.servicesitem__title}>Ремонт и замена выключателей</div>
                        <div className={classes.servicesitem__price}>
                            <div className={classes.servicesitem__price_name}>Стоимость работ</div>
                            <div className={classes.servicesitem__price_price}>от 290 руб.</div>
                        </div>
                    </div>
                    <div className={classes.servicesitem__btn}>
                        {/* <button onClick={openModal}  className={classes.btn}>Оставить заявку</button> */}
                        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
                    </div>
                </div>
                <div className={classes.servicesitem}>
                    <div className={classes.servicesitem__img} style={{backgroundImage: `url(${require('./imgs/newtechnic.webp')})` }}></div>
                    <div className={classes.servicesitem__content}>
                        <div className={classes.servicesitem__title}>	Подключение бытовой техники  </div>
                        <div className={classes.servicesitem__price}>
                            <div className={classes.servicesitem__price_name}>Стоимость работ</div>
                            <div className={classes.servicesitem__price_price}>от 690 руб.</div>
                        </div>
                    </div>
                    <div className={classes.servicesitem__btn}>
                        {/* <button onClick={openModal}  className={classes.btn}>Оставить заявку</button> */}
                        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
                    </div>
                </div>
           

                <div className={classes.servicesitem}>
                    <div className={classes.servicesitem__img} style={{backgroundImage: `url(${require('./imgs/tester.webp')})` }} ></div>
                    <div className={classes.servicesitem__content}>
                        <div className={classes.servicesitem__title}>Ремонт электрощитов и замена электроавтоматов </div>
                        <div className={classes.servicesitem__price}>
                            <div className={classes.servicesitem__price_name}>Стоимость работ</div>
                            <div className={classes.servicesitem__price_price}>от 340 руб.</div>
                        </div>
                    </div>
                    <div className={classes.servicesitem__btn}>
                        {/* <button onClick={openModal}  className={classes.btn}>Оставить заявку</button> */}
                        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
                    </div>
                </div>

                <div className={classes.servicesitem}>
                    <div className={classes.servicesitem__img} style={{backgroundImage: `url(${require('./imgs/lamp.webp')})` }}></div>
                    <div className={classes.servicesitem__content}>
                        <div className={classes.servicesitem__title}>
                            Ремонт и замена осветительных приборов </div>
                        <div className={classes.servicesitem__price}>
                            <div className={classes.servicesitem__price_name}>Стоимость работ</div>
                            <div className={classes.servicesitem__price_price}>от 390 руб.</div>
                        </div>
                    </div>
                    <div className={classes.servicesitem__btn}>
                        {/* <button onClick={openModal}  className={classes.btn}>Оставить заявку</button> */}
                        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
                    </div>
                </div>
     <div className={classes.servicesitem}>
                    <div className={classes.servicesitem__img} style={{backgroundImage: `url(${require('./imgs/fire.webp')})` }} ></div>
                    <div className={classes.servicesitem__content}>
                        <div className={classes.servicesitem__title}>Устранение замыкания и ремонт электропроводки </div>
                        <div className={classes.servicesitem__price}>
                            <div className={classes.servicesitem__price_name}>Стоимость работ</div>
                            <div className={classes.servicesitem__price_price}>от 430 руб.</div>
                        </div>
                    </div>
                    <div className={classes.servicesitem__btn}>
                        {/* <button onClick={openModal}  className={classes.btn}>Оставить заявку</button> */}
                        <a className={classes.btnCall} href={`tel: ${phoneNumber}`}>позвонить</a>
                    </div>
                </div>
                
            </div>
        </section>
    </section>
    
     );
}
 
export default Service;